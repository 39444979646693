<template>
  <div>
    <form-wizard
      ref="formNewCampaing"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Salvar"
      back-button-text="Voltar"
      next-button-text="Avançar"
      class="wizard-vertical mb-3"
      @on-complete="formSubmitted"
    >

      <!-- aba campanha -->
      <tab-content
        title="Campanha"
        icon="feather icon-package"
        :before-change="validateStep1"
      >
        <validation-observer ref="step-1">
          <b-form class="px-5">
            <b-row class="justify-center flex-col mb-3 mt-3">
              <b-col
                cols="12"
                class="mb-2 text-center"
              >
                <h2 class="text-primary">
                  Dê um nome para sua campanha!
                </h2>
                <p>
                  Esta informação será exibida como <b>título do anúncio</b> da sua campanha no <b>painel do indicador</b>.
                </p>
              </b-col>
              <b-col
                md="12"
                class="pr-3 pl-3"
              >
                <b-form-group
                  label="Digite o nome da sua campanha abaixo:"
                  label-for="campaingName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="nome da campanha"
                    rules="required|campaignNameValidation"
                  >
                    <b-form-input
                      v-model="campaingName"
                      :state="errors.length > 0 ? false:null"
                      type="text"
                      placeholder="Ex. Indique a loja da Dona Maria"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <template v-if="companyToken === 'CAHB1' || companyToken === 'CNZLA'">
                  <b-form-group
                    label="URL da logo para a campanha (opcional)"
                    label-for="campaignLogo"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="logo da campanha"
                      rules="url"
                    >
                      <b-form-input
                        v-model="campaignLogo"
                        type="text"
                        placeholder="Adicione o endereço completo do arquivo"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </template>

              </b-col>
            </b-row>
            <b-row class="flex-col mt-100 mb-3">
              <b-col
                cols="12"
                class="mb-2 text-center"
              >
                <h2>
                  Escolha o tipo de conversão da campanha
                </h2>
                <p>
                  Defina como deseja converter seus leads da maneira que melhor se encaixe com sua estratégia de negócio!
                </p>
              </b-col>
              <b-col
                md="12"
              >
                <validation-provider
                  #default="{ errors }"
                  name="tipo de campanha"
                  rules="required"
                >
                  <b-row class="justify-content-center">
                    <!--  -->
                    <b-col
                      v-for="card in cardsTypeCampaingData.infoCards"
                      :key="card.title"
                      md="5"
                      class="d-flex"
                    >
                      <b-card
                        :id="card.name"
                        :name="card.name"
                        align="center"
                        @click="handleCardSelected(card.name)"
                      >
                        <b-form-radio
                          v-model="typeCampaing"
                          name="typeCampaing"
                          :value="card.name"
                        >
                          <b-img
                            v-if="card.img"
                            :src="card.img"
                            class="mb-2 mt-5"
                            alt="basic svg img"
                          />
                          <h3>{{ card.title }}</h3>
                          <b-card-text :name="card.name">
                            {{ card.subtitle }}
                          </b-card-text>

                          <b-list-group class="list-group-circle text-left">
                            <b-list-group-item
                              v-for="(data,index) in card.topics"
                              :key="index"
                              class="d-flex"
                            >
                              <feather-icon
                                icon="CheckIcon"
                                size="15"
                                class="mr-1"
                              />
                              <span style="display: flex; flex: 1">{{ data }}</span>
                            </b-list-group-item>
                          </b-list-group>
                        </b-form-radio>
                      </b-card>
                    </b-col>
                  </b-row>
                  <b-row class="text-center">
                    <b-col>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-col>
                  </b-row>
                </validation-provider>
              </b-col>
            </b-row>
            <!--  -->
            <b-row class="justify-center flex-col mb-75">
              <b-col
                cols="12"
                class="mb-2 text-center"
              >
                <h2>
                  Onde deseja converter o lead?
                </h2>
              </b-col>
              <b-col cols="12">
                <validation-provider
                  #default="{ errors }"
                  name="tipo de site"
                  rules="required"
                >
                  <b-row class="justify-content-center">
                    <b-col
                      md="6"
                      class="text-center"
                    >
                      <b-form-radio
                        v-model="typeLandingPage"
                        name="typeLandingPage"
                        value="indiquei.vc"
                        class="radio-type-landing mb-2 cursor-pointer"
                      >
                        <span>Landing Page indiquei.vc</span>
                      </b-form-radio>
                      <p>Ideal pra você que não possui página web, a indiquei.vc disponibiliza uma landing page personalizada com a cara do seu negócio!</p>
                    </b-col>
                    <b-col
                      md="6"
                      class="text-center"
                    >
                      <b-form-radio
                        v-model="typeLandingPage"
                        name="typeLandingPage"
                        value="site_proprio"
                        class="radio-type-landing mb-2 cursor-pointer"
                      >
                        <span>Site próprio</span>
                      </b-form-radio>
                      <p>Utilizando seu próprio site, ao concluir a criação da campanha, você terá acesso a documentação para efetuar as integrações</p>
                    </b-col>
                  </b-row>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row
              v-if="typeLandingPage === 'site_proprio'"
              class="justify-content-center mt-75"
            >
              <b-col
                cols="12"
                class="mt-1"
              >
                <p class="text-left">
                  Para que as conversões possam ser contabilizadas, será necessário que você configure seu site conforme as especificações da documentação técnica :)
                </p>
              </b-col>
              <b-col
                cols="12"
                class="d-flex justify-content-start align-items-start pl-1 pr-1"
              >
                <b-form-group
                  label="Digite a url de destino"
                  label-for="url"
                >
                  <!-- prepend="https://" -->
                  <b-input-group>
                    <b-input-group-prepend
                      size="lg"
                      class="mr-1"
                    >
                      <v-select
                        v-model="domainPrefixConversionUrl"
                        label="prefix"
                        style="min-width:115px"
                        :options="domainPrefixOptions"
                        :clearable="false"
                      />
                    </b-input-group-prepend>
                    <validation-provider
                      #default="{ errors }"
                      name="endereço da página de conversão"
                      rules="required|url"
                    >
                      <b-form-input
                        v-model="conversionUrl"
                        :state="errors.length > 0 ? false:null"
                        type="text"
                        style="min-width:350px"
                        placeholder="Informe o endereço da página de conversão"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="12"
                class="mt-2"
              >
                <h4 class="d-flex">
                  Deseja ativar o botão de vendas?
                  <b-form-checkbox
                    v-model="salesButton.show"
                    checked="true"
                    class="custom-control-success ml-2"
                    name="check-renovation"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </h4>
              </b-col>

              <template v-if="salesButton.show">
                <b-card>
                  <b-row>
                    <b-col
                      cols="12"
                      class="d-flex justify-content-start align-items-center pr-2 pl-2"
                    >
                      <div
                        style="width: 190px !important;"
                      >
                        <p
                          class="m-0"
                          style="white-space: nowrap;"
                        >
                          <strong>Texto do botão</strong>
                        </p>
                      </div>

                      <div style="width: 100%">
                        <validation-provider
                          #default="{ errors }"
                          name="texto do botão"
                          rules="required|max:40"
                        >
                          <b-form-input
                            v-model="salesButton.text"
                            :state="errors.length > 0 ? false:null"
                            type="text"
                            placeholder="Cadastrar indicação"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </b-col>
                    <!--  -->
                    <b-col
                      cols="12"
                      class="d-flex justify-content-start align-items-center mt-2 pr-2 pl-2"
                    >
                      <div
                        style="width: 190px !important;"
                      >
                        <p class="m-0">
                          <strong>Página de <br>redirecionamento</strong>
                        </p>
                      </div>

                      <div style="width: 100%">
                        <validation-provider
                          #default="{ errors }"
                          name="endereço da página de redirecionamento"
                          rules="required|regex:^[^https:\/\/]"
                        >
                          <b-form-group>
                            <b-input-group prepend="https://">
                              <b-form-input
                                v-model="salesButton.link"
                                :state="errors.length > 0 ? false:null"
                                type="text"
                                style="display: flex"
                                placeholder="Informe o endereço da página"
                              />

                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </div>
                    </b-col>

                  </b-row>
                </b-card>
              </template>
            </b-row>
            <!--  -->
          </b-form>
        </validation-observer>
      </tab-content>

      <!-- aba indicador -->
      <tab-content
        title="Indicador"
        icon="feather icon-user"
        :before-change="validateStep2"
      >
        <banner-header
          name="indicador"
          title="Recompensa do indicador"
          subtitle="Você poderá escolher entre duas opções para beneficiar seu indicador"
          bg-image="step2"
        />
        <validation-observer ref="step-2">
          <b-form>
            <b-row class="mt-3 mb-3 pr-3 pl-3">
              <b-col
                cols="12"
                class="text-center pr-3 pl-3"
              >
                <h1 class="mb-0">
                  Como deseja recompensar o indicador?
                </h1>
              </b-col>
            </b-row>
            <validation-provider
              #default="{ errors }"
              name="tipo de remuneração"
              rules="required"
            >
              <b-row class="pr-3 pl-3">
                <b-col
                  cols="6"
                  class="pr-3 pl-3"
                >
                  <b-form-radio
                    v-model="indicator.typeReward"
                    name="typeRewardIndicator"
                    value="money"
                  >
                    <b-card
                      name="money"
                      align="center"
                      :class="{'checked' : indicator.typeReward === 'money'}"
                      class="cursor-pointer"
                    >
                      <feather-icon
                        icon="DollarSignIcon"
                        size="30"
                      />
                      <h3>Dinheiro</h3>
                      <b-card-text name="money text">
                        Quando a indicação for efetivada, o valor ficará disponível para resgate na área de recompensas.
                      </b-card-text>
                    </b-card>
                  </b-form-radio>
                </b-col>
                <!--  -->
                <b-col
                  cols="6"
                  class="pr-3 pl-3"
                >
                  <b-form-radio
                    v-model="indicator.typeReward"
                    name="typeRewardIndicator"
                    value="voucher"
                  >
                    <b-card
                      name="voucher"
                      align="center"
                      :class="{'checked' : indicator.typeReward === 'voucher'}"
                      class="cursor-pointer"
                    >
                      <feather-icon
                        icon="CreditCardIcon"
                        size="30"
                      />
                      <h3>Cupom</h3>
                      <b-card-text name="voucher text">
                        Quando a indicação for efetivada,
                        seu cupom ficará disponível para
                        resgate na área de recompensas.
                      </b-card-text>
                    </b-card>
                  </b-form-radio>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-col>
              </b-row>
            </validation-provider>
            <!--  -->
            <template v-if="indicator.typeReward === 'money'">
              <b-row
                class="mt-2 mb-3 pr-3 pl-3 box-info"
                style="display: flex; flex-direction: column"
              >
                <b-row>
                  <b-col
                    cols="12"
                  >
                    <h2>
                      Escolha a forma que deseja recompensar
                    </h2>
                  </b-col>

                  <validation-provider
                    #default="{ errors }"
                    name="forma do benefício"
                    rules="required"
                  >
                    <b-col
                      md="12"
                      style="display: flex"
                      class="mt-2"
                    >
                      <b-form-radio
                        v-model="indicator.formatBenefit"
                        name="formatBenefit"
                        value="fixed"
                        class="mb-2 cursor-pointer"
                      >
                        <span>Valor Fixo</span>
                      </b-form-radio>

                      <b-form-radio
                        v-model="indicator.formatBenefit"
                        name="formatBenefit"
                        value="percent"
                        class="mb-2 ml-3 cursor-pointer"
                      >
                        <span>Percentual sobre a venda</span>
                      </b-form-radio>

                    </b-col>
                    <b-col md="12">
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-col>
                  </validation-provider>
                </b-row>

                <!--  -->
                <b-row>
                  <b-col
                    v-if="indicator.formatBenefit !== ''"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="valor do beneficio"
                      rules="required|integer"
                    >
                      <b-form-group
                        label="Informe abaixo o valor a ser pago"
                        label-for="valueBenefit"
                      >
                        <b-input-group
                          v-if="indicator.formatBenefit === 'fixed'"
                          prepend="R$"
                          append=",00"
                        >
                          <b-form-input
                            id="valueBenefit"
                            v-model="indicator.valueBenefit"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Ex. 25"
                            name="valueBenefit"
                          />

                        </b-input-group>
                        <!--  -->
                        <b-input-group
                          v-else
                          append="%"
                        >
                          <b-form-input
                            id="valueBenefit"
                            v-model="indicator.valueBenefit"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Ex. 25"
                            name="valueBenefit"
                          />

                        </b-input-group>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                    <!--  -->
                  </b-col>
                </b-row>
              </b-row>

            </template>

            <template v-if="indicator.typeReward === 'voucher'">
              <b-row
                class="mt-3 mb-3 pr-3 pl-3 box-info"
              >
                <b-col
                  cols="12"
                >
                  <h1>
                    Benefício do cupom
                  </h1>
                  <h3>
                    Determine um benefício qualquer (livre), para recompensar o indicador.
                    Pode ser <b>desconto, produto, serviço, etc. :)</b>

                  </h3>
                </b-col>

                <!-- <b-col
                  cols="1"
                  class="mt-2 pr-1 pl-2 pt-2"
                  style="display: flex; align-items: flex-start; justify-content: center"
                /> -->

                <b-col
                  cols="12"
                  class="mt-2"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="benefício do cupom"
                    rules="required|max:40"
                  >
                    <b-form-group
                      label="Digite no campo abaixo o benefício do cupom"
                      label-for="descriptionVoucherIndicator"
                    >
                      <div style="display: flex; align-items: center">
                        <b-img
                          src="@/assets/images/icons/voucher.svg"
                          alt="svg img"
                          class="mr-2"
                        />
                        <b-form-input
                          id="descriptionVoucherIndicator"
                          v-model="indicator.descriptionVoucher"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Ex. Mensalidade Grátis"
                          name="descriptionVoucherIndicator"
                        />
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>

                  <p class="mt-2">
                    O conteúdo descrito será transformado em cupom após a conversão
                  </p>
                </b-col>

              </b-row>
            </template>

            <!--  -->
            <b-row
              class="mt-3 mb-3 pr-3 pl-3 box-info"
            >
              <b-col
                cols="12"
                class="pr-2 pl-2"
              >
                <h1>
                  Regulamento da campanha
                </h1>
                <h3>Descreva abaixo as regras para compor o regulamento da sua campanha</h3>
              </b-col>

              <b-col
                cols="12"
                class="mt-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="regulamento"
                  rules="required"
                >
                  <quill-editor
                    v-model="indicator.regulation"
                    :options="snowOption"
                  />
                  <small class="text-danger">{{ errors[0] ? 'Excedeu o limite de caracteres.' : '' }}</small>
                </validation-provider>

                <p class="mt-2">
                  Essa informação ficará visível no site de indicações, para ciência dos indicadores.
                </p>
              </b-col>
            </b-row>

            <!-- orientações de resgata do cupom do indicador -->
            <template v-if="indicator.typeReward === 'voucher'">
              <b-row
                class="pr-3 pl-3"
              >
                <b-col
                  cols="12"
                  class="mt-3 pr-2 pl-2"
                >
                  <h2>
                    Orientações de resgate do CUPOM
                  </h2>
                  <span>Defina abaixo como o seu cliente poderá resgatar o CUPOM que será liberado na conversão.</span>
                </b-col>
              </b-row>
              <validation-provider
                #default="{ errors }"
                name="orientações de resgate do cupom"
                rules="required"
              >
                <b-row class="mt-2 ml-2 mr-2">
                  <b-col md="3">
                    <b-card
                      name="presential indicator"
                      align="center"
                      :class="{'checked' : indicator.methodRescueVoucher === 'presential'}"
                      class="cursor-pointer"
                    >
                      <b-form-radio
                        v-model="indicator.methodRescueVoucher"
                        name="methodRescueVoucherIndicator"
                        value="presential"
                        class="cursor-pointer"
                        style="display: flex; flex-direction: column"
                      >
                        <feather-icon
                          icon="HomeIcon"
                          size="20"
                          class="text-primary mb-1"
                        />
                        <h4>Presencial</h4>
                      </b-form-radio>
                    </b-card>
                  </b-col>
                  <b-col md="3">
                    <b-card
                      name="site indicator"
                      align="center"
                      :class="{'checked' : indicator.methodRescueVoucher === 'site'}"
                      class="cursor-pointer"
                    >
                      <b-form-radio
                        v-model="indicator.methodRescueVoucher"
                        name="methodRescueVoucherIndicator"
                        value="site"
                        class="cursor-pointer"
                        style="display: flex; flex-direction: column"
                      >
                        <feather-icon
                          icon="MonitorIcon"
                          size="20"
                          class="text-primary mb-1"
                        />
                        <h4>Site</h4>
                      </b-form-radio>
                    </b-card>
                  </b-col>
                  <b-col md="3">
                    <b-card
                      name="phone indicator"
                      align="center"
                      :class="{'checked' : indicator.methodRescueVoucher === 'phone'}"
                      class="cursor-pointer"
                    >
                      <b-form-radio
                        v-model="indicator.methodRescueVoucher"
                        name="methodRescueVoucherIndicator"
                        value="phone"
                        class="cursor-pointer"
                        style="display: flex; flex-direction: column"
                      >
                        <feather-icon
                          icon="PhoneIcon"
                          size="20"
                          class="text-primary mb-1"
                        />
                        <h4>Telefone</h4>
                      </b-form-radio>
                    </b-card>
                  </b-col>
                  <b-col md="3">
                    <b-card
                      name="other indicator"
                      align="center"
                      :class="{'checked' : indicator.methodRescueVoucher === 'other'}"
                      class="cursor-pointer"
                    >
                      <b-form-radio
                        v-model="indicator.methodRescueVoucher"
                        name="methodRescueVoucherIndicator"
                        value="other"
                        class="cursor-pointer"
                        style="display: flex; flex-direction: column"
                      >
                        <feather-icon
                          icon="GridIcon"
                          size="20"
                          class="text-primary mb-1"
                        />
                        <h4>Outro</h4>
                      </b-form-radio>
                    </b-card>
                  </b-col>
                  <b-col cols="12">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-col>
                </b-row>

              </validation-provider>

              <b-row
                v-if="indicator.methodRescueVoucher !== ''"
                class="mt-2"
              >
                <b-col
                  lg="12"
                  class="mx-auto px-5"
                >
                  <div class="d-flex justify-content-between">
                    <div class="text-center text-md-left mt-3">
                      <h3 class="text-primary">
                        {{ indicator.methodRescueVoucher === 'presential' && 'Informe o endereço completo do estabelecimento' ||
                          indicator.methodRescueVoucher === 'site' && 'Informe o site que será utilizado para efetuar o resgate do cupom' ||
                          indicator.methodRescueVoucher === 'phone' && 'Informe o telefone para que possa ser realizado o resgate do cupom' ||
                          indicator.methodRescueVoucher === 'other' && 'Descreva abaixo como deverá ser realizado o resgate do cupom' }}
                      </h3>
                      <b-row>
                        <b-col cols="8">
                          <!-- opção de presencial -->
                          <template v-if="indicator.methodRescueVoucher === 'presential'">
                            <validation-provider
                              #default="{ errors }"
                              name="endereço"
                              rules="required"
                            >
                              <b-form-textarea
                                id="endereco"
                                v-model="indicator.howToRescueVoucher"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Ex. Nome da rua, bairro, número, cidade e cep"
                                name="endereco"
                                rows="3"
                                no-resize
                              />
                              <!-- <b-form-input
                                id="endereco"
                                v-model="indicator.howToRescueVoucher"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Digite o endereço completo"
                                name="endereco"
                              /> -->
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </template>
                          <!-- opção de site -->
                          <template v-if="indicator.methodRescueVoucher === 'site'">
                            <b-input-group>
                              <b-input-group-prepend
                                size="lg"
                                class="mr-1"
                              >
                                <v-select
                                  v-model="domainPrefixIndicatorSiteRescue"
                                  label="prefix"
                                  style="min-width:115px"
                                  :options="domainPrefixOptions"
                                  :clearable="false"
                                />
                              </b-input-group-prepend>
                              <validation-provider
                                #default="{ errors }"
                                name="site"
                                rules="required|url"
                              >
                                <b-form-input
                                  id="site"
                                  v-model="indicator.howToRescueVoucher"
                                  :state="errors.length > 0 ? false:null"
                                  placeholder="seusite.com.br"
                                  name="site"
                                />

                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-input-group>
                          </template>
                          <!-- opção de telefone -->
                          <template v-if="indicator.methodRescueVoucher === 'phone'">
                            <validation-provider
                              #default="{ errors }"
                              name="telefone"
                              rules="required|integer"
                            >
                              <b-form-input
                                id="phone"
                                v-model="indicator.howToRescueVoucher"
                                class="form-control"
                                :state="errors.length > 0 ? false:null"
                                placeholder="xx xxxxx-xxxx"
                              />
                              <!-- <b-input-group>
                                <cleave
                                  id="phone"
                                  v-model="indicator.howToRescueVoucher"
                                  class="form-control"
                                  :raw="false"
                                  :options="options.phone"
                                  placeholder="xx xxxxx-xxxx"
                                />
                              </b-input-group> -->
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </template>
                          <!-- opção de outros -->
                          <template v-if="indicator.methodRescueVoucher === 'other'">
                            <validation-provider
                              #default="{ errors }"
                              name="intruções"
                              rules="required|max:160"
                            >
                              <b-form-textarea
                                id="other"
                                v-model="indicator.howToRescueVoucher"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Máximo de 160 caracteres"
                                name="other"
                                rows="3"
                                no-resize
                              />
                              <!-- <b-form-input
                                id="other"
                                v-model="indicator.howToRescueVoucher"
                                :state="errors.length > 0 ? false:null"
                                placeholder=""
                                name="other"
                              /> -->
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </template>
                        </b-col>
                      </b-row>
                    </div>
                    <!-- images -->
                    <b-img
                      fluid
                      src="@/assets/images/illustration/pricing-Illustration.svg"
                      class="pricing-trial-img"
                      alt="svg img"
                    />
                    <!--/ images -->
                  </div>
                </b-col>
              </b-row>
            </template>
            <!--  -->
          </b-form>
        </validation-observer>
      </tab-content>

      <!-- aba indicado -->
      <tab-content
        title="Indicado"
        icon="feather icon-users"
        :before-change="validateStep3"
      >
        <banner-header
          name="indicado"
          title="Benefício do indicado"
          subtitle="Configure aqui o que o indicado receberá ao adquirir seu produto ou serviço"
          bg-image="step3"
        />
        <validation-observer ref="step-3">
          <b-form>
            <b-row class="mt-3 mb-3 pr-3 pl-3 box-info">
              <b-col
                cols="12"
              >
                <h1 class="mb-2 font-bold">
                  Benefício do indicado (novo cliente)
                </h1>
                <h3 class="mb-2">
                  Informe o benefício concedido para o novo cliente
                </h3>
              </b-col>
              <b-col
                cols="12"
                class="mt-2 pr-2 pl-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="benefício do cupom"
                  rules="required|max:40"
                >
                  <b-form-group
                    label="Seu amigo ganha"
                    label-for="descriptionVoucherIndicated"
                  >
                    <b-form-input
                      id="descriptionVoucherIndicated"
                      v-model="indicated.descriptionVoucher"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Ex: 10% de desconto em qualquer produto"
                      name="descriptionVoucherIndicated"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="12"
                class="mt-2 pr-2 pl-2"
              >
                <span> A partir do conteúdo informado, será gerado um cupom exclusivo para o indicado realizar sua primeira compra.</span>
              </b-col>
            </b-row>

            <b-row
              class="mt-3 mb-3 pr-3 pl-3 box-info"
            >
              <b-col
                cols="12"
                class="pr-2 pl-2"
              >
                <h1 class="mb-2 font-bold">
                  Regulamento do benefício ao novo cliente
                </h1>
                <h3 class="mb-2">
                  Descreva abaixo as regras de resgate do benefício
                </h3>
              </b-col>

              <b-col
                cols="12"
                class="mt-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="regulamento"
                  rules="required"
                >
                  <quill-editor
                    v-model="indicated.regulation"
                    :options="snowOption"
                  />
                  <small class="text-danger">{{ errors[0] ? 'Excedeu o limite de caracteres.' : '' }}</small>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row
              v-if="typeCampaing !== 'voucherGenerate'"
              class="mt-3 mb-3 pr-3 pl-3 box-info"
            >
              <b-col
                cols="12"
                class="pr-2 pl-2"
              >
                <h1 class="mb-2 font-bold">
                  Expiração do cupom
                </h1>
                <h3 class="mb-2">
                  Defina um prazo <b>em dias corridos</b> para utilização do cupom. Se não houver prazo para vencimento selecione “0”
                </h3>
                <!-- <p>Será contabilizado o tempo considerando a data de geração do cupom. <br> <b>Dia de hoje  + período selecionado abaixo em dias</b> </p>
                <span>Caso não queira definir um prazo selecione a opção 0 (zero)</span> -->
              </b-col>
              <b-col
                md="6"
                class="mt-2"
              >
                <v-select
                  v-model="indicated.daysToExpiredVoucher"
                  label="days"
                  :options="option"
                />

                <span>No momento em que criamos o cupom, é contabilizado o periodo em dias corridos e atribuida a data de vencimento do mesmo. </span>
              </b-col>
            </b-row>

            <!-- orientações de resgata do cupom do indicado -->
            <template>
              <b-row
                class="pr-3 pl-3"
              >
                <b-col
                  cols="12"
                  class="mt-3 pr-2 pl-2"
                >
                  <h1 class="mb-2 font-bold">
                    Como resgatar o cupom
                  </h1>
                  <h3 class="mb-2">
                    Defina abaixo como seu cliente poderá resgatar o CUPOM quando for liberado como recompensa
                  </h3>
                </b-col>
              </b-row>
              <validation-provider
                #default="{ errors }"
                name="orientações de resgate do cupom"
                rules="required"
              >
                <b-row class="mt-2 ml-2 mr-2">
                  <b-col md="3">
                    <b-card
                      name="presential indicated"
                      align="center"
                      :class="{'checked' : indicated.methodRescueVoucher === 'presential'}"
                      class="cursor-pointer"
                    >
                      <b-form-radio
                        v-model="indicated.methodRescueVoucher"
                        name="methodRescueVoucherIndicated"
                        value="presential"
                        class="cursor-pointer"
                        style="display: flex; flex-direction: column"
                      >
                        <feather-icon
                          icon="HomeIcon"
                          size="20"
                          class="text-primary mb-1"
                        />
                        <h4>Presencial</h4>
                      </b-form-radio>
                    </b-card>
                  </b-col>
                  <b-col md="3">
                    <b-card
                      name="site indicated"
                      align="center"
                      :class="{'checked' : indicated.methodRescueVoucher === 'site'}"
                      class="cursor-pointer"
                    >
                      <b-form-radio
                        v-model="indicated.methodRescueVoucher"
                        name="methodRescueVoucherIndicated"
                        value="site"
                        class="cursor-pointer"
                        style="display: flex; flex-direction: column"
                      >
                        <feather-icon
                          icon="MonitorIcon"
                          size="20"
                          class="text-primary mb-1"
                        />
                        <h4>Site</h4>
                      </b-form-radio>
                    </b-card>
                  </b-col>
                  <b-col md="3">
                    <b-card
                      name="phone indicated"
                      align="center"
                      :class="{'checked' : indicated.methodRescueVoucher === 'phone'}"
                      class="cursor-pointer"
                    >
                      <b-form-radio
                        v-model="indicated.methodRescueVoucher"
                        name="methodRescueVoucherIndicated"
                        value="phone"
                        class="cursor-pointer"
                        style="display: flex; flex-direction: column"
                      >
                        <feather-icon
                          icon="PhoneIcon"
                          size="20"
                          class="text-primary mb-1"
                        />
                        <h4>Telefone</h4>
                      </b-form-radio>
                    </b-card>
                  </b-col>
                  <b-col md="3">
                    <b-card
                      name="other indicated"
                      align="center"
                      :class="{'checked' : indicated.methodRescueVoucher === 'other'}"
                      class="cursor-pointer"
                    >
                      <b-form-radio
                        v-model="indicated.methodRescueVoucher"
                        name="methodRescueVoucherIndicated"
                        value="other"
                        class="cursor-pointer"
                        style="display: flex; flex-direction: column"
                      >
                        <feather-icon
                          icon="GridIcon"
                          size="20"
                          class="text-primary mb-1"
                        />
                        <h4>Outro</h4>
                      </b-form-radio>
                    </b-card>
                  </b-col>
                  <b-col cols="12">
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-col>
                </b-row>

              </validation-provider>

              <b-row
                v-if="indicated.methodRescueVoucher !== ''"
                class="mt-2"
              >
                <b-col
                  lg="10"
                  class="mx-auto"
                >
                  <div class="d-flex justify-content-between">
                    <div class="text-center text-md-left mt-3">
                      <h3 class="text-primary">
                        {{ indicated.methodRescueVoucher === 'presential' && 'Informe o endereço completo do estabelecimento' ||
                          indicated.methodRescueVoucher === 'site' && 'Informe o site que será utilizado para efetuar o resgate do cupom' ||
                          indicated.methodRescueVoucher === 'phone' && 'Informe o telefone para que possa ser realizado o resgate do cupom' ||
                          indicated.methodRescueVoucher === 'other' && 'Descreva abaixo como deverá ser realizado o resgate do cupom' }}
                      </h3>
                      <b-row>
                        <b-col cols="10">
                          <!-- opção de presencial -->
                          <template v-if="indicated.methodRescueVoucher === 'presential'">
                            <validation-provider
                              #default="{ errors }"
                              name="endereço"
                              rules="required"
                            >
                              <b-form-textarea
                                id="endereco"
                                v-model="indicated.howToRescueVoucher"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Ex. Nome da rua, bairro, número, cidade e cep"
                                name="endereco"
                                rows="3"
                                no-resize
                              />
                              <!-- <b-form-input
                                id="endereco"
                                v-model="indicated.howToRescueVoucher"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Digite o endereço completo"
                                name="endereco"
                              /> -->
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </template>
                          <!-- opção de site -->
                          <template v-if="indicated.methodRescueVoucher === 'site'">
                            <b-input-group>
                              <!-- v-if="typeCampaing !== 'voucherGenerate'" -->
                              <b-input-group-prepend
                                size="lg"
                                class="mr-1"
                              >
                                <v-select
                                  v-model="domainPrefixIndicatedSiteRescue"
                                  label="prefix"
                                  style="min-width:115px"
                                  :options="domainPrefixOptions"
                                  :clearable="false"
                                />
                              </b-input-group-prepend>
                              <validation-provider
                                #default="{ errors }"
                                name="site"
                                rules="required|url"
                              >
                                <b-form-input
                                  id="site"
                                  v-model="indicated.howToRescueVoucher"
                                  :state="errors.length > 0 ? false:null"
                                  placeholder="seusite.com.br"
                                  name="site"
                                />

                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-input-group>
                            <!-- <p v-else>
                              <b-form-input
                                id="site"
                                :placeholder="typeLandingPage === 'indiquei.vc' ? urlConversionIndiquei : `${domainPrefixIndicatedSiteRescue.prefix}${conversionUrl}`"
                                name="site"
                                disabled
                              />
                            </p> -->
                          </template>
                          <!-- opção de telefone -->
                          <template v-if="indicated.methodRescueVoucher === 'phone'">
                            <validation-provider
                              #default="{ errors }"
                              name="telefone"
                              rules="required|integer"
                            >
                              <b-form-input
                                id="phone"
                                v-model="indicated.howToRescueVoucher"
                                class="form-control"
                                :state="errors.length > 0 ? false:null"
                                placeholder="xx xxxxx-xxxx"
                              />
                              <!-- <b-input-group> -->
                              <!-- <cleave
                                  id="phone"
                                  v-model="indicated.howToRescueVoucher"
                                  class="form-control"
                                  :raw="false"
                                  :options="options.phone"
                                  placeholder="xx xxxxx-xxxx"
                                /> -->
                              <!-- </b-input-group> -->
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </template>
                          <!-- opção de outros -->
                          <template v-if="indicated.methodRescueVoucher === 'other'">
                            <validation-provider
                              #default="{ errors }"
                              name="intruções"
                              rules="required|max:160"
                            >
                              <b-form-textarea
                                id="other"
                                v-model="indicated.howToRescueVoucher"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Máximo de 160 caracteres"
                                name="other"
                                rows="3"
                                no-resize
                              />
                              <!-- <b-form-input
                                id="other"
                                v-model="indicated.howToRescueVoucher"
                                :state="errors.length > 0 ? false:null"
                                placeholder=""
                                name="other"
                              /> -->
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </template>
                        </b-col>
                      </b-row>
                    </div>
                    <!-- images -->
                    <b-img
                      fluid
                      src="@/assets/images/illustration/pricing-Illustration.svg"
                      class="pricing-trial-img"
                      alt="svg img"
                    />
                    <!--/ images -->
                  </div>
                </b-col>
              </b-row>
            </template>

          </b-form>
        </validation-observer>
      </tab-content>

      <!-- aba compartilhamento -->
      <tab-content
        title="Compartilhamento"
        icon="feather icon-send"
        :before-change="validateStep4"
      >
        <banner-header
          name="compartilhamento"
          title="Compartilhamento"
          subtitle="Você pode personalizar o texto de compartilhamento que o indicador utilizará para divulgar sua campanha!"
          bg-image="step4"
        />
        <validation-observer ref="step-4">
          <b-form>
            <b-row class="mt-3 mb-3 pr-3 pl-3 box-info">
              <b-col
                cols="12"
                class="pr-3 pl-3"
              >
                <h1 class="mb-2 font-bold">
                  Texto de compartilhamento
                </h1>
                <h3 class="mb-2">
                  Digite abaixo a mensagem para ser compartilhada pelas rede sociais
                </h3>
              </b-col>
              <b-col
                cols="9"
                class="mt-2 pr-3 pl-3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="texto de compartilhamento"
                  rules="required"
                >
                  <b-form-textarea
                    id="sharedMessage"
                    ref="sharedMessage"
                    v-model="sharedMessage"
                    :state="errors.length > 0 ? false:null"
                    placeholder=""
                    rows="5"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

                <p class="mt-2">
                  Observação: Esse texto será o mesmo utilizado para todos os meios de compartilhamento: WhatsApp, Facebook, E-mail e Copiar
                </p>
              </b-col>
              <!-- MENU DE CONTEXTO -->
              <b-col
                cols="3"
                class="mt-2"
              >
                <b-dropdown
                  variant="link"
                  no-caret
                  class="chart-dropdown"
                  toggle-class="p-0"
                >
                  <template #button-content>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      name="btnmenucontexto"
                      variant="primary"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        size="18"
                        class="text-light cursor-pointer"
                      />
                      Adicionar variável
                    </b-button>
                  </template>
                  <b-dropdown-item
                    href="javascript:void(0)"
                    @click="addSharedVariableToText('{nome_empresa}')"
                  >
                    Nome da empresa
                  </b-dropdown-item>
                  <b-dropdown-item
                    href="javascript:void(0)"
                    @click="addSharedVariableToText('{codigo_indicador}')"
                  >
                    Código do Indicador
                  </b-dropdown-item>
                  <b-dropdown-item
                    href="javascript:void(0)"
                    @click="addSharedVariableToText('{pagina_destino}')"
                  >
                    Página de destino
                  </b-dropdown-item>
                  <b-dropdown-item
                    href="javascript:void(0)"
                    @click="addSharedVariableToText('{beneficio_indicado}')"
                  >
                    Benefício do indicado
                  </b-dropdown-item>
                  <b-dropdown-item
                    href="javascript:void(0)"
                    @click="addSharedVariableToText('{onde_resgatar}')"
                  >
                    Onde resgatar
                  </b-dropdown-item>
                </b-dropdown>

              </b-col>
              <!-- FIM DO MENU DE CONTEXTO -->
            </b-row>

            <b-row>
              <b-col
                cols="12"
                class="pr-3 pl-3 mt-2"
              >
                <h4 class="mb-0">
                  Você poderá utilizar informações dinâmicas para compor o texto, <b>basta adicionar a variável</b> onde deseja exibir a informação
                </h4>
              </b-col>
              <b-col
                cols="12"
                class="mt-2 pr-3 pl-3"
              >
                <!--  -->
                <b-list-group>
                  <b-list-group-item
                    v-clipboard:copy="varSharedMessage.nomeEmpresa"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    class="d-flex"
                    style="cursor: pointer"
                  >
                    <span
                      class="mr-1"
                    >
                      <feather-icon
                        icon="CopyIcon"
                        size="16"
                      />
                    </span>
                    <span>{nome_empresa} - Nome da empresa.</span>
                  </b-list-group-item>

                  <b-list-group-item
                    v-clipboard:copy="varSharedMessage.codigoIndicador"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    class="d-flex"
                    style="cursor: pointer"
                  >
                    <span
                      class="mr-1"
                    >
                      <feather-icon
                        icon="CopyIcon"
                        size="16"
                      />
                    </span>
                    <span>{codigo_indicador} - Código do indicador.</span>
                  </b-list-group-item>

                  <b-list-group-item
                    v-clipboard:copy="varSharedMessage.paginaDestino"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    class="d-flex"
                    style="cursor: pointer"
                  >
                    <span
                      class="mr-1"
                    >
                      <feather-icon
                        icon="CopyIcon"
                        size="16"
                      />
                    </span>
                    <span>{pagina_destino} - URL da página de destino.</span>
                  </b-list-group-item>

                  <b-list-group-item
                    v-clipboard:copy="varSharedMessage.beneficioIndicado"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    class="d-flex"
                    style="cursor: pointer"
                  >
                    <span
                      class="mr-1"
                    >
                      <feather-icon
                        icon="CopyIcon"
                        size="16"
                      />
                    </span>
                    <span>{beneficio_indicado} - Benefício concedido ao novo cliente.</span>
                  </b-list-group-item>

                  <b-list-group-item
                    v-clipboard:copy="varSharedMessage.ondeResgatar"
                    v-clipboard:success="onCopy"
                    v-clipboard:error="onError"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    class="d-flex"
                    style="cursor: pointer"
                  >
                    <span
                      class="mr-1"
                    >
                      <feather-icon
                        icon="CopyIcon"
                        size="16"
                      />
                    </span>
                    <span>{onde_resgatar} - Informação de onde resgatar o cupom do novo cliente.</span>
                  </b-list-group-item>

                </b-list-group>
              <!--  -->
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </tab-content>
      <!-- aba compartilhamento -->
      <tab-content
        title="Resumo"
        icon="feather icon-list"
      >
        <b-row class="mt-2 mb-3 pr-3 pl-3">
          <b-col
            cols="12"
            class="pr-3 pl-3"
          >
            <h2>Veja o resumo da sua campanha <br>
              <b>Atenção</b>, após a criação os dados não poderão ser alterados!
            </h2>
          </b-col>
          <b-col
            cols="12"
            class="mt-2 pr-3 pl-3"
          >
            <app-collapse accordion>
              <app-collapse-item title="Campanha">
                <b-row class="pr-2 pl-2">
                  <b-col cols="5">
                    <div style="display: -webkit-inline-box">
                      <feather-icon
                        icon="CheckIcon"
                        size="15"
                        class="mr-1"
                        color="#6270FF"
                      />
                      <p><strong>Nome da Campanha:</strong> <br>{{ campaingName }}</p>
                    </div>

                    <div style="display: -webkit-inline-box">
                      <feather-icon
                        icon="CheckIcon"
                        size="15"
                        class="mr-1"
                        color="#6270FF"
                      />
                      <p><strong>Página de Conversão:</strong> <br>{{ typeLandingPage === 'indiquei.vc' ? `${urlConversionIndiquei}/cupom` : conversionUrl }}</p>
                    </div>

                    <template v-if="salesButton.show">
                      <div style="display: -webkit-inline-box">
                        <feather-icon
                          icon="CheckIcon"
                          size="15"
                          class="mr-1"
                          color="#6270FF"
                        />
                        <p><strong>Texto do botão de vendas</strong> <br> {{ salesButton.text }}</p>
                      </div>
                      <div style="display: -webkit-inline-box">
                        <feather-icon
                          icon="CheckIcon"
                          size="15"
                          class="mr-1"
                          color="#6270FF"
                        />
                        <p><strong>Página de redirecionamento do botão de vendas</strong> <br> {{ `https://${salesButton.link}` }}</p>
                      </div>
                    </template>
                  </b-col>
                  <b-col cols="5">
                    <div style="display: -webkit-inline-box">
                      <feather-icon
                        icon="CheckIcon"
                        size="15"
                        class="mr-1"
                        color="#6270FF"
                      />
                      <p><strong>Forma de Conversão:</strong> <br>{{ typeCampaing === 'leadGenerate' ? 'Geração de Lead' : 'Geração de Venda' }}</p>
                    </div>
                  </b-col>
                  <b-col cols="2">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      class="btn-icon"
                      @click="() => this.$refs.formNewCampaing.changeTab(4, 0)"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="15"
                      />
                      Editar seção
                    </b-button>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <!--  -->
              <app-collapse-item title="Recompensa do indicador">
                <b-row class="pr-2 pl-2">
                  <b-col cols="5">
                    <div style="display: -webkit-inline-box">
                      <feather-icon
                        icon="CheckIcon"
                        size="15"
                        class="mr-1"
                        color="#6270FF"
                      />
                      <p><strong>Maneira de recompensar:</strong> <br>{{ indicator.typeReward === 'money' ? 'Dinheiro' : 'Cupom' }}</p>
                    </div>

                    <div
                      v-if="indicator.typeReward === 'money'"
                      style="display: -webkit-inline-box"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        size="15"
                        class="mr-1"
                        color="#6270FF"
                      />
                      <p><strong>{{ indicator.formatBenefit === 'fixed' ? 'Valor da Recompensa:' : 'Percentual da Compra:' }}</strong> <br>
                        {{ indicator.formatBenefit === 'fixed' ? `R$ ${indicator.valueBenefit},00` : `${indicator.valueBenefit}%` }}</p>
                    </div>

                    <div
                      v-if="indicator.typeReward === 'voucher'"
                      style="display: -webkit-inline-box"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        size="15"
                        class="mr-1"
                        color="#6270FF"
                      />
                      <p><strong>Regulamento da campanha: </strong> <br> <span v-html="indicator.regulation" /> </p>
                    </div>
                  </b-col>

                  <b-col cols="5">
                    <div
                      v-if="indicator.typeReward === 'voucher'"
                      style="display: -webkit-inline-box"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        size="15"
                        class="mr-1"
                        color="#6270FF"
                      />
                      <p><strong>Descrição do Cupom: </strong> <br> {{ indicator.descriptionVoucher }}</p>
                    </div>

                    <div
                      v-if="indicator.typeReward === 'money'"
                      style="display: -webkit-inline-box"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        size="15"
                        class="mr-1"
                        color="#6270FF"
                      />
                      <p><strong>Tipo de recompensa</strong> <br>
                        {{ indicator.formatBenefit === 'fixed' ? 'Valor fixo' : '% sobre a venda' }}</p>
                    </div>
                  </b-col>
                  <b-col cols="2">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      class="btn-icon"
                      @click="() => this.$refs.formNewCampaing.changeTab(4, 1)"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="15"
                      />
                      Editar seção
                    </b-button>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <!--  -->
              <app-collapse-item title="Benefício do indicado">
                <b-row class="pr-2 pl-2">
                  <b-col cols="5">
                    <div
                      style="display: -webkit-inline-box"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        size="15"
                        class="mr-1"
                        color="#6270FF"
                      />
                      <p><strong>Benefício do indicado</strong> <br>
                        {{ indicated.descriptionVoucher }}</p>
                    </div>

                    <div
                      style="display: -webkit-inline-box"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        size="15"
                        class="mr-1"
                        color="#6270FF"
                      />
                      <p><strong>Prazo de expiração do cupom</strong> <br>
                        {{ indicated.daysToExpiredVoucher.days !== '00' && typeCampaing === 'voucherGenerate' ? `${indicated.daysToExpiredVoucher.days} dias` : 'Indeterminado' }}</p>
                    </div>

                    <div
                      style="display: -webkit-inline-box"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        size="15"
                        class="mr-1"
                        color="#6270FF"
                      />
                      <p><strong>Regulamento da campanha: </strong> <br> <span v-html="indicated.regulation" /> </p>
                    </div>
                  </b-col>
                  <b-col cols="5">
                    <div
                      style="display: -webkit-inline-box"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        size="15"
                        class="mr-1"
                        color="#6270FF"
                      />
                      <p><strong>Como resgatar</strong> <br>
                        {{
                          (indicated.methodRescueVoucher === 'presential' && 'Presencial') ||
                            (indicated.methodRescueVoucher === 'phone' && 'Telefone') ||
                            (indicated.methodRescueVoucher === 'site' && 'Site') ||
                            (indicated.methodRescueVoucher === 'other' && 'Outra')
                        }}
                      </p>
                    </div>

                    <div
                      style="display: -webkit-inline-box"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        size="15"
                        class="mr-1"
                        color="#6270FF"
                      />
                      <p><strong>Informação sobre como resgatar</strong> <br>
                        {{ getInfoHowToRescueIndicated }}
                      </p>
                    </div>
                  </b-col>
                  <b-col cols="2">
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      class="btn-icon"
                      @click="() => this.$refs.formNewCampaing.changeTab(4, 2)"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="15"
                      />
                      Editar seção
                    </b-button>
                  </b-col>
                </b-row>
              </app-collapse-item>
              <!--  -->
              <app-collapse-item title="Compartilhamento">
                <b-row class="pr-2 pl-2">
                  <b-col
                    cols="9"
                  >
                    <div style="display: -webkit-inline-box">
                      <feather-icon
                        icon="CheckIcon"
                        size="15"
                        class="mr-1"
                        color="#6270FF"
                      />
                      <p><strong>Texto de compartilhamento:</strong> <br> {{ sharedMessage }}</p>
                    </div>
                  </b-col>
                  <b-col
                    cols="2"
                    offset="1"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      class="btn-icon"
                      @click="() => this.$refs.formNewCampaing.changeTab(4, 3)"
                    >
                      <feather-icon
                        icon="EditIcon"
                        size="15"
                      />
                      Editar seção
                    </b-button>
                  </b-col>
                </b-row>
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>

      </tab-content>
    </form-wizard>

    <!-- modal com instruções do script -->
    <b-modal
      v-model="modalScriptShow"
      title="Instruções para integração do iframe"
      ok-title="Ok"
      centered
      ok-only
      @ok="handleOk"
    >
      <b-card-body class="mt-1 mb-2 pr-3">
        <h5><strong> Campanha criada com sucesso 🎉</strong></h5>
        <b-card-text class="font-small-3">
          Antes de ativá-la, certifique que a página de destino da campanha
          esteja configurada corretamente para que seus usuários possam
          começar a indicar!
        </b-card-text>

        <h4 class="mt-2">
          Instruções para integração
        </h4>
        <p>Siga as intruções abaixo para fazer a integração com sua página de conversão.</p>

        <p>1. Copie e cole o código abaixo e adicione após a tag <br> <code>&#60;&#47;body&#62; </code> do seu site.</p>

        <p>
          <code>
            &#60;script
            id="indiquei-vc-init"
            src="{{ urlConversionIndiquei }}/init.js"
            onload="init()"
            async
            defer
            &#47;&#62;
          </code>
        </p>

        <p class="mt-2">
          2. Você deve adicionar o elemento HTML <br> <code>&#60;indiquei-vc-widget&#62;&#60;&#47;indiquei-vc-widget&#62;</code> dentro do código html da página onde deseja que o formulário de conversão apareça.
        </p>
      </b-card-body>
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import { api } from '@axios'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import jwtDecode from 'jwt-decode'
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput, BInputGroup,
  BFormTextarea,
  BForm,
  BFormRadio,
  BFormCheckbox,
  BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BDropdown, BDropdownItem, BInputGroupPrepend, BCardBody,

} from 'bootstrap-vue'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

// import Cleave from 'vue-cleave-component'

import scrollToFirstElementFormWithError from '@/utils/scrollToFirstElementFormWithError'

import { quillEditor } from 'vue-quill-editor'

import { FormWizard, TabContent } from 'vue-form-wizard'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  required, integer, url, regex, campaignNameValidation,
} from '@validations'
// import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

// import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

import BannerHeader from './components/BannerHeader.vue'
import cardsTypeCampaingData from './data/campaing-type-card-info.json'

import campaignStoreModule from '../campaignStoreModule'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BFormTextarea,
    BForm,
    BFormRadio,
    BFormCheckbox,
    // Cleave,

    // card
    BCard,
    BImg,
    BCardText,
    BListGroup,
    BListGroupItem,
    BCardBody,

    //
    BannerHeader,

    //
    AppCollapse,
    AppCollapseItem,
    BButton,
    BDropdown,
    BDropdownItem,

    // vee-validate
    ValidationProvider,
    ValidationObserver,

    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BInputGroupPrepend,

    quillEditor,
    // flatPickr,
  },
  directives: {
    Ripple,
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-campaign'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, campaignStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })
  },
  data() {
    // validação se está duplicando uma campanha
    const duplicate = this.$store.getters['app-campaign/getIsDuplicatingCampaing']
    // dados da campanha que está sendo duplicada
    const mirrorCampaing = this.$store.getters['app-campaign/getCampaignTemp']

    return {
      companyToken: JSON.parse(localStorage.getItem('userData')).CompanyToken,
      domainPrefixConversionUrl: { prefix: 'https://' },
      domainPrefixIndicatorSiteRescue: { prefix: 'https://' },
      domainPrefixIndicatedSiteRescue: { prefix: 'https://' },
      urlConversionIndiquei: `https://${jwtDecode(window.localStorage.getItem('accessToken')).SystemUrl}`,
      campaingName: duplicate ? `${mirrorCampaing.name} Cópia` : '',
      campaignLogo: duplicate ? mirrorCampaing.campaignLogo : '',
      typeCampaing: duplicate ? mirrorCampaing.campaignType : '',
      typeLandingPage: duplicate && mirrorCampaing.conversionUrl === 'indiquei.vc' ? 'indiquei.vc' : '',
      conversionUrl: '',
      salesButton: {
        text: duplicate ? mirrorCampaing.salesButton.text : '',
        show: duplicate ? mirrorCampaing.salesButton.show : false,
        link: '',
      },
      indicator: {
        typeReward: duplicate ? mirrorCampaing.benefitIndicator.type : '',
        formatBenefit: duplicate ? mirrorCampaing.benefitIndicator.format : '',
        valueBenefit: duplicate ? mirrorCampaing.benefitIndicator.amount : '',
        descriptionVoucher: duplicate ? mirrorCampaing.benefitIndicator.description : '',
        regulation: duplicate ? mirrorCampaing.benefitIndicator.regulation : '<p>Será considerada a primeira conversão, promoção sem prazo de expiração, sendo possível estar desativada a qualquer momento.</p>',
        methodRescueVoucher: duplicate ? mirrorCampaing.benefitIndicator.whereToRedeem.type : '',
        howToRescueVoucher: duplicate ? mirrorCampaing.benefitIndicator.whereToRedeem.info : '',
      },
      indicated: {
        descriptionVoucher: duplicate ? mirrorCampaing.benefitIndicated.benefit : '',
        regulation: duplicate ? mirrorCampaing.benefitIndicated.regulation : ' <p>Este cupom é intransferível e válido para a primeira compra (para novos clientes).</p><p>Aceito apenas na loja x</p> <p>Validade do cupom: 30 dias </p> <p>Após a validade não será mais aceito</p>',
        daysToExpiredVoucher: duplicate ? { days: `${mirrorCampaing.benefitIndicated.daysToExpired}` } : { days: '00' },
        methodRescueVoucher: duplicate ? mirrorCampaing.benefitIndicated.whereToRedeem.type : '',
        howToRescueVoucher: duplicate ? mirrorCampaing.benefitIndicated.whereToRedeem.info : '',
      },
      sharedMessage: duplicate ? mirrorCampaing.sharedMessage : 'Oi eu sou cliente na {nome_empresa} e estou te enviando um cupom {beneficio_indicado} na sua primeira compra. Esse é meu código {codigo_indicador}. Acesse o link {pagina_destino} para mais informações.',
      messagesToTextWhereToRedeem: {
        presential: 'Compareça no endereço e apresente seu CUPOM para ganhar sua recompensa!',
        phone: 'Ligue no número e informe seu CUPOM para ganhar sua recompensa!',
        site: 'Acesse o site e informe seu CUPOM para ganhar sua recompensa!',
      },

      option: [
        { days: '00' },
        { days: '01' }, { days: '02' }, { days: '03' }, { days: '04' }, { days: '05' },
        { days: '06' }, { days: '07' }, { days: '08' }, { days: '09' }, { days: '10' },
        { days: '11' }, { days: '12' }, { days: '13' }, { days: '14' }, { days: '15' },
        { days: '16' }, { days: '17' }, { days: '18' }, { days: '19' }, { days: '20' },
        { days: '21' }, { days: '22' }, { days: '23' }, { days: '24' }, { days: '25' },
        { days: '26' }, { days: '27' }, { days: '28' }, { days: '29' }, { days: '30' },
        { days: '31' }, { days: '32' }, { days: '33' }, { days: '34' }, { days: '35' },
        { days: '36' }, { days: '37' }, { days: '38' }, { days: '39' }, { days: '40' },
        { days: '41' }, { days: '42' }, { days: '43' }, { days: '44' }, { days: '45' },
        { days: '46' }, { days: '47' }, { days: '48' }, { days: '49' }, { days: '50' },
        { days: '51' }, { days: '52' }, { days: '53' }, { days: '54' }, { days: '55' },
        { days: '56' }, { days: '57' }, { days: '58' }, { days: '59' }, { days: '60' },
        { days: '61' }, { days: '62' }, { days: '63' }, { days: '64' }, { days: '65' },
        { days: '66' }, { days: '67' }, { days: '68' }, { days: '69' }, { days: '70' },
        { days: '71' }, { days: '72' }, { days: '73' }, { days: '74' }, { days: '75' },
        { days: '76' }, { days: '77' }, { days: '78' }, { days: '79' }, { days: '80' },
        { days: '81' }, { days: '82' }, { days: '83' }, { days: '84' }, { days: '85' },
        { days: '86' }, { days: '87' }, { days: '88' }, { days: '89' }, { days: '90' },
      ],
      options: {
        phone: {
          blocks: [2, 5, 4],
          uppercase: true,
        },
      },
      required,
      integer,
      url,
      regex,
      campaignNameValidation,
      cardsTypeCampaingData,
      snowOption: {
        theme: 'snow',
      },
      modalScriptShow: false,
      varSharedMessage: {
        nomeEmpresa: '{nome_empresa}',
        codigoIndicador: '{codigo_indicador}',
        paginaDestino: '{pagina_destino}',
        beneficioIndicado: '{beneficio_indicado}',
        ondeResgatar: '{onde_resgatar}',
      },
      domainPrefixOptions: [
        { prefix: 'https://' },
        { prefix: 'http://' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getCampaignTemp: 'app-campaign/getCampaignTemp',
      getIsDuplicatingCampaing: 'app-campaign/getIsDuplicatingCampaing',
    }),
    getInfoHowToRescueIndicated() {
      if (this.typeCampaing === 'voucherGenerate' && this.indicated.methodRescueVoucher === 'site' && this.typeLandingPage === 'indiquei.vc') {
        return `https://${jwtDecode(window.localStorage.getItem('accessToken')).SystemUrl}`
      }
      if (this.typeCampaing === 'voucherGenerate' && this.indicated.methodRescueVoucher === 'site' && this.typeLandingPage === 'site_proprio') {
        return `${this.domainPrefixConversionUrl.prefix}${this.conversionUrl}`
      }
      return this.indicated.howToRescueVoucher
    },
  },
  mounted() {
    localize('pt_BR')
  },
  methods: {
    validateStep1() {
      return new Promise((resolve, reject) => {
        this.$refs['step-1'].validate()
          .then(success => {
            if (success) {
              return resolve(true)
            }
            scrollToFirstElementFormWithError()
            return resolve(false)
            // return new Error('correct all values')
          })
          .catch(error => reject(error))
      })
    },
    validateStep2() {
      return new Promise((resolve, reject) => {
        this.$refs['step-2'].validate()
          .then(success => {
            if (success) {
              return resolve(true)
            }
            scrollToFirstElementFormWithError()
            return resolve(false)
            // return new Error('correct all values')
          })
          .catch(error => reject(error))
      })
    },
    validateStep3() {
      return new Promise((resolve, reject) => {
        this.$refs['step-3'].validate()
          .then(success => {
            if (success) {
              return resolve(true)
            }
            scrollToFirstElementFormWithError()
            return resolve(false)
            // return new Error('correct all values')
          })
          .catch(error => reject(error))
      })
    },
    validateStep4() {
      return new Promise((resolve, reject) => {
        this.$refs['step-4'].validate()
          .then(success => {
            if (success) {
              return resolve(true)
            }
            scrollToFirstElementFormWithError()
            return resolve(false)
            // return new Error('correct all values')
          })
          .catch(error => reject(error))
      })
    },
    formSubmitted() {
      useJwt.createCampaing({
        name: this.campaingName,
        campaignType: this.typeCampaing,
        campaignLogo: this.campaignLogo,
        conversionUrl: this.typeLandingPage === 'indiquei.vc' ? `https://${jwtDecode(window.localStorage.getItem('accessToken')).SystemUrl}/cupom` : `${this.domainPrefixConversionUrl.prefix}${this.conversionUrl}`,
        status: 'DESATIVADA',
        salesButton: {
          text: this.salesButton.text,
          show: this.salesButton.show,
          link: `https://${this.salesButton.link}`,
        },
        benefitIndicator: {
          type: this.indicator.typeReward,
          amount: this.indicator.typeReward === 'money' ? parseInt(this.indicator.valueBenefit, 0) : 0,
          format: this.indicator.typeReward === 'money' ? this.indicator.formatBenefit : '',
          description: this.indicator.typeReward === 'voucher' ? this.indicator.descriptionVoucher : null,
          regulation: this.indicator.regulation,
          whereToRedeem: {
            type: this.indicator.methodRescueVoucher,
            info: this.indicator.methodRescueVoucher !== 'site' ? this.indicator.howToRescueVoucher : `${this.domainPrefixIndicatorSiteRescue.prefix}${this.indicator.howToRescueVoucher}`,
            text: (this.indicator.methodRescueVoucher === 'presential' && this.messagesToTextWhereToRedeem.presential)
            || (this.indicator.methodRescueVoucher === 'phone' && this.messagesToTextWhereToRedeem.phone)
            || (this.indicator.methodRescueVoucher === 'site' && this.messagesToTextWhereToRedeem.site)
            || (this.indicator.methodRescueVoucher === 'other' && '')
            || '',

          },
        },
        benefitIndicated: {
          benefit: this.indicated.descriptionVoucher,
          regulation: this.indicated.regulation,
          daysToExpired: this.typeCampaing === 'leadGenerate' ? parseInt(this.indicated.daysToExpiredVoucher.days, 0) : 0,
          whereToRedeem: {
            type: this.indicated.methodRescueVoucher,
            info: this.getInfoHowToRescueIndicated,

            text: (this.indicated.methodRescueVoucher === 'presential' && this.messagesToTextWhereToRedeem.presential)
            || (this.indicated.methodRescueVoucher === 'phone' && this.messagesToTextWhereToRedeem.phone)
            || (this.indicated.methodRescueVoucher === 'site' && this.messagesToTextWhereToRedeem.site)
            || (this.indicated.methodRescueVoucher === 'other' && ''),
          },
        },
        sharedMessage: this.sharedMessage,
      })
        .then(response => {
          // se for url indiquei
          if (this.typeLandingPage === 'indiquei.vc') {
            this.$router.push({ name: 'campaigns-list' })
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Campanha cadastrada com sucesso!',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })

            return response
          }

          this.modalScriptShow = true
          return response
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ocorreu um erro ao tentar cadastar sua campanha. Tente novamente',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
            },
          })
          return error
        })
      // .finally(() => resolve({ data: [...getters.sortCampaigns] }))
    },
    handleOk() {
      this.$router.push({ name: 'campaigns-list' })
    },
    handleCardSelected(card) {
      const cardLead = document.querySelector('#leadGenerate')
      const cardVoucher = document.querySelector('#voucherGenerate')

      if (card === 'leadGenerate') {
        cardLead.classList.add('checked')
        cardVoucher.classList.remove('checked')
      } else {
        cardVoucher.classList.add('checked')
        cardLead.classList.remove('checked')
      }
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Variável copiada!',
          icon: 'BellIcon',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Ocorreu um erro!',
          icon: 'BellIcon',
        },
      })
    },
    addSharedVariableToText(variavel) {
      this.sharedMessage = `${this.sharedMessage} ${variavel}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';

  .box-info {
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
      padding: 2rem 1rem 3rem;
      margin: 1rem;
      border-radius: 0.5rem;
    }

  .checked { border: 1px solid #153BC7; }

  .radio-type-landing{
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    span{
      font-size: 1.225rem;
      font-weight: 400;
      cursor: pointer;
    }
  }
</style>
