import { api } from '@axios'
import useJwt from '@/auth/jwt/useJwt'

const types = {
  STORE_CAMPAIGNS: 'storeCampaigns',
  SET_PARAMS: 'setParams',
  CREATE_CAMPAING: 'createCampaing',
  TOGGLE_IS_DUPLICATING_CAMPAING: 'toggleIsDuplicatingCampaing',
}

export default {
  namespaced: true,
  state: {
    sortBy: 'token',
    sortDesc: false,
    isDuplicatingCampaing: false,
    campaingTemp: {
      token: '',
      name: '',
      campaignLogo: '',
      campaignType: '',
      conversionUrl: '',
      regulation: '',
      salesButton: {
        text: '',
        show: false,
        link: '',
      },
      benefitIndicator: {
        amount: 0,
        description: '',
        format: '',
        regulation: '',
        type: '',
        whereToRedeem: {
          info: '',
          text: '',
          type: '',
        },
      },
      benefitIndicated: {
        benefit: '',
        daysToExpired: '',
        regulation: '',
        whereToRedeem: {
          info: '',
          text: '',
          type: '',
        },
      },
      sharedMessage: '',
    },
  },
  getters: {
    sortCampaigns: state => state.campaignsData.sort((a, b) => {
      if (state.sortDesc) {
        if (a[state.sortBy] > b[state.sortBy]) return 1
        if (a[state.sortBy] < b[state.sortBy]) return -1
        return 0
      }
      if (a[state.sortBy] < b[state.sortBy]) return 1
      if (a[state.sortBy] > b[state.sortBy]) return -1
      return 0
    }),
    getCampaignTemp: state => state.campaingTemp,
    getIsDuplicatingCampaing: state => state.isDuplicatingCampaing,
  },
  mutations: {
    storeCampaigns: (state, campaignsData) => {
      const campaigns = campaignsData.map(item => {
        const status = item.status === 'ATIVADA'
        return { ...item, status }
      })
      state.campaignsData = [...campaigns]
    },
    setParams: (state, { sortBy, sortDesc }) => {
      state.sortBy = sortBy
      state.sortDesc = sortDesc
    },
    createCampaing: (state, payload) => {
      state.campaingTemp = payload
    },
    toggleIsDuplicatingCampaing: (state, payload) => {
      state.isDuplicatingCampaing = payload
    },
  },
  actions: {
    fetchCampaigns() {
      // commit(types.SET_PARAMS, params)
      return new Promise((resolve, reject) => {
        // if (!state.campaignsData) {
        useJwt.listCampaing()
          .then(response => {
            const campaingsFiltered = response.data.filter(campaing => campaing.status !== 'ARQUIVADA')
            const campaingsSort = campaingsFiltered.sort((a, b) => a.name - b.name)

            return resolve(campaingsSort)
          })
          .catch(error => reject(error))
        // início do trecho comentado
        // api
        //   .get('/campaign/list')
        //   .then(response => resolve(response.data.filter(campaing => campaing.status !== 'ARQUIVADA')))
        //   .catch(error => reject(error))
          // final do trecho comentado
        // } else {
        //   resolve({ data: [...getters.sortCampaigns] })
        // }
      })
    },
    fetchCampaign(ctx, { id }) {
      return new Promise((resolve, reject) => {
        api
          .get(`/apps/invoice/invoices/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createCampaing({ commit }, payload) {
      commit(types.CREATE_CAMPAING, payload)
    },
    handleStatusChange(ctx, data) {
      const status = data.status === true ? 'ATIVADA' : 'DESATIVADA'
      useJwt.updateStatusCampaing(data.token, status)
    },
    handleArchiveCampaing(ctx, data) {
      useJwt.updateStatusCampaing(data.token, 'ARQUIVADA')
    },
    getCampaignDetails(ctx, { token }) {
      return new Promise((resolve, reject) => {
        useJwt.getCampaingByToken(token)
          .then(
            // response => console.log(response),
            ({ data }) => this.dispatch('app-campaign/createCampaing', {
              token: data.token,
              name: data.name,
              campaignLogo: data.campaignLogo ? data.campaignLogo : '',
              campaignType: data.campaignType,
              conversionUrl: data.conversionUrl,
              regulation: data.regulation,
              salesButton: {
                text: data?.salesButton?.text,
                show: data?.salesButton?.show,
                link: data?.salesButton?.link,
              },
              benefitIndicator: {
                amount: data.benefitIndicator.amount,
                description: data.benefitIndicator.description,
                format: data.benefitIndicator.format,
                regulation: data.benefitIndicator.regulation,
                type: data.benefitIndicator.type,
                whereToRedeem: {
                  info: data.benefitIndicator.whereToRedeem.info,
                  text: data.benefitIndicator.whereToRedeem.text,
                  type: data.benefitIndicator.whereToRedeem.type,
                },
              },
              benefitIndicated: {
                benefit: data.benefitIndicated.benefit,
                daysToExpired: data.benefitIndicated.daysToExpired,
                regulation: data.benefitIndicated.regulation,
                whereToRedeem: {
                  info: data.benefitIndicated.whereToRedeem.info,
                  text: data.benefitIndicated.whereToRedeem.text,
                  type: data.benefitIndicated.whereToRedeem.type,
                },
              },
              sharedMessage: data.sharedMessage,
            }),
          )
          .catch(error => reject(error))
      })
    },
    toggleIsDuplicatingCampaing({ commit }, payload) {
      commit(types.TOGGLE_IS_DUPLICATING_CAMPAING, payload)
    },
  },
}
