<template>
  <section id="faq-search-filter">
    <b-card
      v-if="bgImage === 'step2'"
      no-body
      :name="title"
      class="faq-search p-4"
      :style="{backgroundImage:`url(${require('@/assets/images/banner/create-campaign/step2.png')})`}"
      style="background-size: cover ; background-position: center; background-repeat: no-repeat"
    >
      <div
        :name="{title}"
        class="text-center"
      >
        <h2 class="text-primary">
          {{ title }}
        </h2>
        <template
          :name="subtitle"
        >
          <span v-html="subtitle" />
        </template></div>
    </b-card>
    <!--  -->
    <b-card
      v-if="bgImage === 'step3'"
      no-body
      :name="title"
      class="faq-search p-4"
      :style="{backgroundImage:`url(${require('@/assets/images/banner/create-campaign/step3.png')})`}"
      style="background-size: cover ; background-position: center; background-repeat: no-repeat"
    >
      <div
        :name="{title}"
        class="text-center"
      >
        <h2 class="text-primary">
          {{ title }}
        </h2>
        <template
          :name="subtitle"
        >
          <span v-html="subtitle" />
        </template></div>
    </b-card>
    <!--  -->
    <b-card
      v-if="bgImage === 'step4'"
      no-body
      :name="title"
      class="faq-search p-4"
      :style="{backgroundImage:`url(${require('@/assets/images/banner/create-campaign/step4.png')})`}"
      style="background-size: cover ; background-position: center; background-repeat: no-repeat"
    >
      <div
        :name="{title}"
        class="text-center"
      >
        <h2 class="text-primary">
          {{ title }}
        </h2>
        <template
          :name="subtitle"
        >
          <span v-html="subtitle" />
        </template></div>
    </b-card>
  </section>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  name: 'BannerHeader',
  components: {
    BCard,
  },
  props: {
    title: String,
    subtitle: String,
    bgImage: {
      type: String,
      default: 'step2',
    },
  },
  // computed: {
  //   resolveBgImage() {
  //     return `url(${require('@/assets/images/banner/create-campaign/step2.png')})`
  //   }
  // }
}
</script>

<style lang="scss" scoped>
    @import '@core/scss/vue/pages/page-faq.scss';
</style>
